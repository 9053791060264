import { throwError, Observable, Subscription } from 'rxjs';
import { SessionStorageService } from 'app/core/services/session-storage.service';
import { DateTime } from 'luxon';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NavigationService } from './navigation.service';
import { LogoutReason } from '../enums/logout-reason.enum';
import { DateUtils } from '../lib/date-utils';
import { AppTabIdSessionKey } from '../constants';
import { AppVersion } from '../lib/app-version';
import { AuthenticationService, UserLogoutEvent } from './authentication.service';
import { WebsocketService } from './websocket.service';
import { nanoid } from 'nanoid';
import { WindowActivityEvent, WindowActivityService } from './window-activity.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ApplicationService implements OnDestroy {
  private navigationService: NavigationService = null;

  private userLoginSubscription: Subscription = null;
  private userLogoutSubscription: Subscription = null;

  private windowActivitySusbcription: Subscription;
  private lastSessionCheckIn: DateTime;
  private sessionExpirationTime: DateTime;
  private sessionExpirationLogoutTimeout: ReturnType<typeof setTimeout>;

  private static sessionExpirationTimeStorageKey = 'sessionExpirationTime';
  private static activityCheckInPeriodMilliseconds = 60 * 1000 * 5;

  constructor(
    private authService: AuthenticationService,
    private sessionStorageService: SessionStorageService,
    private windowActivityService: WindowActivityService,
    private websocketService: WebsocketService,
    private injector: Injector,
    private localStorageService: LocalStorageService,
  ) {}

  public initialize(): Observable<any> {
    this.sessionStorageService.set(AppTabIdSessionKey, nanoid());

    const msg = !environment.production
      ? `Starting R1 340B Recovery, version: ${AppVersion.humanize()}, tabId: ${this.appTabId}`
      : 'Starting R1 340B Recovery';
    console.log(msg);

    this.initDebugUtils();
    this.initAuthEventListeners();
    this.initLocalStorageListener();

    this.subscribeToWindowActivity();

    // fix for circular deps between router and http, let's see if this can be improved
    // it's also likely that the login service can be decomposed a bit more into this service
    this.navigationService = this.injector.get(NavigationService);

    return this.authService.reloadSession().pipe(
      tap(() => {
        this.connectWebsocket();
      }),
      catchError(err => {
        this.authService.logout(LogoutReason.appInitError);
        return throwError(err);
      })
    );
  }

  public resetSessionCheckInTime() {
    const nextTimeout = DateTime.now().plus({ milliseconds: ApplicationService.activityCheckInPeriodMilliseconds });

    if (environment.debugUserIdle) {
      const formattedTime = DateUtils.formatDateTime(nextTimeout.toJSDate());
      this.logActivityMessage(`Resetting check in time: ${formattedTime}`);
    }

    this.lastSessionCheckIn = DateTime.now();
  }

  public setSessionExpirationLogoutTime(timeoutMilliseconds: number) {
    const expirationTime = DateTime.now().plus({ milliseconds: timeoutMilliseconds });
    this.setSessionExpirationLogoutTimeout(expirationTime, timeoutMilliseconds);
  }

  private setSessionExpirationLogoutTimeout(expirationTime: DateTime, timeoutMilliseconds: number) {
    if (this.sessionExpirationLogoutTimeout) {
      clearTimeout(this.sessionExpirationLogoutTimeout);
    }

    if (environment.debugUserIdle) {
      const formattedTime = DateUtils.formatDateTime(expirationTime.toJSDate());
      this.logActivityMessage(`Setting logout timeout ${formattedTime}`);
    }

    this.sessionExpirationTime = expirationTime;
    this.localStorageService.set(ApplicationService.sessionExpirationTimeStorageKey, expirationTime.toISO());

    this.sessionExpirationLogoutTimeout = setTimeout(() => {
      this.logActivityMessage('Logging out gracefully before session expiration');
      this.authService.logout(LogoutReason.inactivity);
    }, timeoutMilliseconds);
  }

  ngOnDestroy() {
    if (this.userLoginSubscription) {
      this.userLoginSubscription.unsubscribe();
    }

    if (this.userLogoutSubscription) {
      this.userLogoutSubscription.unsubscribe();
    }

    if (this.windowActivitySusbcription) {
      this.windowActivitySusbcription.unsubscribe();
    }
  }

  private initAuthEventListeners() {
    this.userLoginSubscription = this.authService.userLogin.subscribe(() => this.handleUserLogin());
    this.userLogoutSubscription = this.authService.userLogout.subscribe((logoutEvent: UserLogoutEvent) =>
      this.handleUserLogout(logoutEvent)
    );
  }

  private initLocalStorageListener() {
    this.localStorageService.observe(ApplicationService.sessionExpirationTimeStorageKey).subscribe(
      (storageEvent: StorageEvent) => {
        if (this.sessionExpirationTime) {
          const newExpriationTime = DateTime.fromISO(JSON.parse(storageEvent.newValue));
          const newExpirationDiff = newExpriationTime.diff(this.sessionExpirationTime, 'seconds');

          // Session expiration time is provided from the server in seconds.  Anything less than a second should be ignored.
          if (newExpirationDiff.seconds > 1) {
            const newExpirationNowDiff = newExpriationTime.diffNow();
            this.setSessionExpirationLogoutTimeout(newExpriationTime, Math.abs(newExpirationNowDiff.milliseconds));
          }
        }
      }
    )
  }

  private get appTabId(): string {
    return this.sessionStorageService.get(AppTabIdSessionKey);
  }

  private initDebugUtils() {
    if (!environment.production) {
      window['printTokenInfo'] = this.printTokenInfo.bind(this);
      window['printUserInfo'] = this.printUserInfo.bind(this);
    }
  }

  private handleUserLogin() {
    this.connectWebsocket();
    this.navigationService.redirectToReturnUrl();
  }

  private handleUserLogout({ logoutReason, appTabId }: UserLogoutEvent) {
    const includeReason = logoutReason === LogoutReason.inactivity;
    const skipReturnUrl = logoutReason === LogoutReason.userLoggedOut;
    const redirectToLogin = logoutReason !== LogoutReason.creatingNewPassword || appTabId !== this.appTabId;
    const forcePageReload =
      logoutReason === LogoutReason.inactivity ||
      logoutReason === LogoutReason.userLoggedOut;

    this.disconnectWebsocket();

    if (redirectToLogin) {
      const queryParams = includeReason ? { logoutReason } : {};
      this.navigationService.redirectToLogin(queryParams, skipReturnUrl, forcePageReload);
    }
  }

  private printTokenInfo() {
    if (this.authService.isLoggedIn) {
      const idToken = this.authService.idToken;

      console.log(`id token authenticated at: ${DateUtils.formatDateTime(idToken.authenticatedAt)}`);
      console.log(`id token issued at: ${DateUtils.formatDateTime(idToken.issuedAt)}`);
      console.log(`id token expires at: ${DateUtils.formatDateTime(idToken.expiresAt)}`);
      console.log(`id token payload:`);
      console.log(JSON.stringify(this.authService.idToken.payload, null, 2));

      const accessToken = this.authService.accessToken;

      console.log(`access token issued at: ${DateUtils.formatDateTime(accessToken.issuedAt)}`);
      console.log(`access token expires at: ${DateUtils.formatDateTime(accessToken.expiresAt)}`);
      console.log(`access token payload:`);
      console.log(JSON.stringify(this.authService.accessToken.payload, null, 2));
    }
  }

  private printUserInfo = (authService: AuthenticationService) => () => {
    if (authService && authService.isLoggedIn) {
      console.log(JSON.stringify(authService.currentUser, null, 2));
    }
  };

  private connectWebsocket() {
    if (this.authService.isLoggedIn) {
      this.websocketService.connect(this.authService.jwtIdentity);
    }
  }

  private disconnectWebsocket() {
    this.websocketService.disconnect();
  }

  private subscribeToWindowActivity() {
    this.windowActivitySusbcription =
      this.windowActivityService.activity.subscribe((activityEvent: WindowActivityEvent) => {
        if (activityEvent.active) {
          this.sessionCheckInIfNeeded();
        }

        this.logoutIfPastSessionExpiration();
      });
  }

  private sessionCheckInIfNeeded() {
    if (this.lastSessionCheckIn) {
      const duration = this.lastSessionCheckIn.diffNow('milliseconds');

      if (Math.abs(duration.milliseconds) > ApplicationService.activityCheckInPeriodMilliseconds) {
        this.resetSessionCheckInTime();

        this.authService.sessionCheckIn().subscribe(
          () => {
            this.logActivityMessage('Checked in');
          },
          err => {
            console.error(err);
          }
        );
      }
    }
  }

  // Normally the session expiration timer should fire and cause the logout, but in some cases it seems
  // a long timer in a background tab doesn't correctly handle the timeout.
  private logoutIfPastSessionExpiration() {
    if (this.sessionExpirationTime) {
      const duration = this.sessionExpirationTime.diffNow('milliseconds');

      if (duration.milliseconds < 0) {
        this.logActivityMessage('Logging out due to activity past session expiration time');
        this.authService.logout(LogoutReason.inactivity);
      }
    }
  }

  private logActivityMessage(msg: string) {
    if (environment.debugUserIdle) {
      console.log(`${msg} [${this.appTabId}] `);
    }
  }
}
